<script lang="ts" setup>
import { Grid, Autoplay } from 'swiper/modules'

const props = defineProps<{
  content: object
}>()

const isLoaded = ref(false)
const numberSlides = ref(0)
if (props.content.partners)
  numberSlides.value = props.content.partners.data.length
</script>

<template>
  <section
    v-if="content"
    id="hero-home"
    data-test="block"
    class="hero-home bg-primary"
  >
    <div class="relative">
      <div
        class="absolute left-0 right-0 top-1/2 -translate-y-1/2"
        v-if="content.title"
      >
        <UContainer>
          <h1
            class="max-w-[90%] font-montserrat text-30 font-semibold leading-45 text-white lg:text-40 lg:font-bold lg:leading-60"
          >
            {{ content.title }}
          </h1>
        </UContainer>
      </div>
      <AkImage
        no-twic-size-mobile="400"
        no-twic-size="2500"
        no-twic
        class="home-hero__bg -z-1 bg pointer-events-none h-[350px] w-full object-cover object-bottom"
        :image="content.background"
        :image-mobile="content['background_mobile']"
      />
    </div>

    <UContainer class="relative">
      <div
        :class="{
          'pb-80 lg:pb-110':
            !content.partners ||
            (content.partners && !content.partners.data.length),
        }"
      >
        <AkText class="hero-home__text" :text="content.text" />
      </div>
      <AkButton
        v-if="content.button"
        class="mt-32 max-lg:w-full max-lg:justify-center lg:mt-36"
        :button="content.button"
      />
      <div
        v-if="content.partners && content.partners.data.length"
        class="mt-60 pb-60 lg:mt-66 lg:pb-30"
      >
        <Swiper
          :modules="[Grid, Autoplay]"
          class="relative"
          :class="[isLoaded ? 'opacity-100' : 'opacity-0']"
          :breakpoints="{
            768: { slidesPerView: 3, slidesPerGroup: 3 },
            1280: {
              slidesPerView: 5,
              slidesPerGroup: numberSlides >= 10 ? 5 : 2,
            },
          }"
          :autoplay="{ delay: 5000 }"
          loop
          :slides-per-view="2"
          :slides-per-group="2"
          space-between="18"
          @swiper="isLoaded = true"
        >
          <SwiperSlide
            v-for="partner in content.partners.data"
            class="max-lg:aspect-[1/1] lg:!h-auto"
          >
            <div
              :data-twic-background="
                partner.attributes.background.data
                  ? useGetTwicUrlBackground(partner.attributes.background)
                  : ''
              "
              class="hero-home__item"
            >
              <div
                class="mb-20 flex h-50 w-50 items-center justify-center lg:h-60 lg:w-60"
              >
                <AkImage
                  v-if="partner.attributes.logo"
                  class="max-h-full max-w-full rounded-10"
                  :image="partner.attributes.logo"
                />
              </div>
              <div class="mt-auto text-center">
                <p class="text-h6 text-primary">
                  {{ partner.attributes.name }}
                </p>
                <p class="text-primary text-p4">
                  {{ partner.attributes.type }}
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </UContainer>
  </section>
</template>

<style lang="scss" scoped>
.hero-home {
  &__item {
    @apply text-primary relative z-10 flex h-full flex-col items-center rounded-10 bg-white bg-cover px-6 py-20 lg:px-20;

    &::before {
      content: '';
      @apply absolute bottom-0 left-0 right-0 top-0 -z-10 rounded-10 opacity-50;
      background-image: linear-gradient(to bottom, #002f54, #f9f9f9);
    }
  }

  &__text {
    @apply mt-40 text-18 leading-28 tracking-[0.18px] text-blue-200 lg:max-w-[60%] lg:text-20 lg:leading-30 lg:tracking-[0.20px];

    :deep(p) {
      @apply text-18 leading-28 tracking-[0.18px] lg:text-20 lg:leading-30 lg:tracking-[0.20px];
    }
  }
}
</style>
